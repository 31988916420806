import React from 'react';

export const FinanceConfig = {
    settings: {
        layout: {
            config: {},
        },
    },
    routes  : [
        {
            path       : "/frais",
            component  :  React.lazy(() => import("app/main/components/finance/fee/ShowFees/Fees")),
            permissions: ["display_supply"],
        },
        {
            path       : "/rapports",
            component  :  React.lazy(() => import("app/main/components/finance/report/generateReport/Reports")),
            permissions: ["display_supply"],
        },
        {
            path       : "/source-marketing",
            component  :  React.lazy(() => import("app/main/components/finance/leadSource/showLeadSources/LeadSources")),
            permissions: ["manage_lead_sources"],
        },
    ],
};
