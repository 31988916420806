import React from "react";

export const PbxQueueConfig = {
    settings: {
        layout: {
            config: {},
        },
    },
    routes  : [
        //callRates
        {
            path       : "/pbxQueues/:id/currentUsers",
            component  : React.lazy(() => import("app/main/components/services/pbxQueue/UsersInQueue")),
            permissions: ["display_pbx_queues"],
        },
        {
            path       : "/pbxQueues",
            component  : React.lazy(() => import("app/main/components/services/pbxQueue/PbxQueues")),
            permissions: ["manage_pbx_queues"],
        },

    ],
};
