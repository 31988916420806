import React from 'react';

export const InventoryConfig = {
    settings: {
        layout: {
            config: {},
        },
    },
    routes  : [
        //delivery
        {
            path       : "/livraisons/ajouter/:delivery_id/:emplacement_id",
            component  : React.lazy(() => import("app/main/components/inventory/delivery/AddSupplyToDelivery/AddSupplyToDelivery")),
            permissions: ["manage_delivery"],
        },
        {
            path       : "/livraisons/recuperer/:id",
            component  : React.lazy(() => import("app/main/components/inventory/delivery/RecuperateDelivery/RecuperateDelivery")),
            permissions: ["manage_delivery"],
        },
        {
            path       : "/livraisons/bac/:id",
            component  : React.lazy(() => import("app/main/components/inventory/delivery/AddBacToDelivery/AddBacToDelivery")),
            permissions: ["display_delivery"],
        },
        {
            path       : "/livraisons/:id",
            component  : React.lazy(() => import("app/main/components/inventory/delivery/showDelivery/ShowDelivery")),
            permissions: ["display_delivery"],
        },
        {
            path       : "/livraisons",
            component  : React.lazy(() => import("app/main/components/inventory/delivery/showDeliveries/Deliveries")),
            permissions: ["display_delivery"],
        },
        {
            path       : "/retour",
            component  : React.lazy(() => import("app/main/components/inventory/delivery/showDeliveryReturn/ShowDeliveryReturn")),
            permissions: ["display_delivery"],
        },

        //emplacement
        {
            path       : "/emplacements/deplacer",
            component  : React.lazy(() => import("app/main/components/inventory/emplacement/massMove/MassMoveEmplacement")),
            permissions: ["manage_supply_emplacement"],
        },
        {
            path       : "/emplacements/:id",
            component  : React.lazy(() => import("app/main/components/inventory/emplacement/showEmplacement/ShowEmplacement")),
            permissions: ["display_supply_emplacement"],
        },

        //minimum
        {
            path       : "/minimums",
            component  : React.lazy(() => import("app/main/components/inventory/minimum/Minimums")),
            permissions: ["display_supply_minimum"],
        },

        //stocks
        {
            path       : "/equipements/creer",
            component  : React.lazy(() => import("app/main/components/inventory/stock/createStock/CreateStock")),
            permissions: ["manage_stored_supply"],
        },
        {
            path       : "/equipements/recertifier",
            component  : React.lazy(() => import("app/main/components/inventory/stock/ReturningStocks/ReturningStocks")),
            permissions: ["manage_stored_supply"],
        },
        {
            path       : "/equipements/retrouver",
            component  : React.lazy(() => import("app/main/components/inventory/stock/findStock/FindStocks")),
            permissions: ["move_supply"],
        },
        {
            path       : "/equipements/modifier/:id",
            component  : React.lazy(() => import("app/main/components/inventory/stock/UpdateStock/UpdateStock")),
            permissions: ["manage_stored_supply"],
        },
        {
            path       : "/equipements/scan",
            component  : React.lazy(() => import("app/main/components/inventory/stock/scanStock/ScanStock")),
            permissions: ["display_stored_supply"],
        },
        {
            path       : "/equipements/:id",
            component  : React.lazy(() => import("app/main/components/inventory/stock/showStock/ShowStockPage")),
            permissions: ["display_stored_supply"],
        },
        {
            path       : "/equipements",
            component  : React.lazy(() => import("app/main/components/inventory/stock/showStocks/Stocks")),
            permissions: ["display_stored_supply"],
        },

        //supplies
        {
            path       : "/quincailleries",
            component  : React.lazy(() => import("app/main/components/inventory/supply/showHardware/HardwareList")),
            permissions: ["display_supply"],
        },
        {
            path       : "/materiaux/retrouver",
            component  : React.lazy(() => import("app/main/components/inventory/supply/findSupply/FindSupply")),
            permissions: ["move_supply"],
        },
        {
            path       : "/materiaux/modifier/:id",
            component  : React.lazy(() => import("app/main/components/inventory/supply/updateSupply/UpdateSupply")),
            permissions: ["manage_supply"],
        },
        {
            path       : "/materiaux/nouveau-materiel",
            component  : React.lazy(() => import("app/main/components/inventory/supply/createSupply/CreateSupply")),
            permissions: ["manage_supply"],
        },
        {
            path       : "/materiaux/:id",
            component  : React.lazy(() => import("app/main/components/inventory/supply/showSupply/ShowSupply")),
            permissions: ["display_supply"],
        },
        {
            path       : "/materiaux",
            component  : React.lazy(() => import("app/main/components/inventory/supply/showSupplies/Supplies")),
            permissions: ["display_supply"],
        },

        //supplyBundle
        {
            path       : "/reception",
            component  : React.lazy(() => import("app/main/components/inventory/supplyBundle/commandeReception/CreateSupplyBundle")),
            permissions: ["display_supply"],
        },

        //SupplySource
        {
            path       : "/sources/nouveau-source/:id",
            component  : React.lazy(() => import("app/main/components/inventory/supplySource/createSupplySource/CreateSupplySource")),
            permissions: ["manage_supply_source"],
        },
        {
            path       : "/sources/modifier/:id",
            component  : React.lazy(() => import("app/main/components/inventory/supplySource/UpdateSupplySource/UpdateSupplySource")),
            permissions: ["manage_supply_source"],
        },

        //tools
        {
            path       : "/outils/routeurs",
            component  : React.lazy(() => import("app/main/components/inventory/tools/routerBench/RouterBenchPage")),
            permissions: ["manage_stored_supply"],
        },
        {
            path       : "/outils/decodeurs",
            component  : React.lazy(() => import("app/main/components/inventory/tools/StbBench/StbOnBench")),
            permissions: ["manage_stored_supply"],
        },

        //truck
        {
            path       : "/camions/historique",
            component  : React.lazy(() => import("app/main/components/inventory/truck/truckHistoric/TruckHistoric")),
            permissions: ["display_truck"],
        },
        {
            path       : "/camions/inventaires",
            component  : React.lazy(() => import("app/main/components/inventory/truck/truckInventory/TruckInventory")),
            permissions: ["display_supply_emplacement"],
        },
        {
            path       : "/camions/nouveau-camion",
            component  : React.lazy(() => import("app/main/components/inventory/truck/createTruck/CreateTruck")),
            permissions: ["manage_truck"],
        },
        {
            path       : "/camions/modifier/:id",
            component  : React.lazy(() => import("app/main/components/inventory/truck/updateTruck/UpdateTruck")),
            permissions: ["manage_truck"],
        },
        {
            path       : "/camions/:id",
            component  : React.lazy(() => import("app/main/components/inventory/truck/showTruck/TruckPage")),
            permissions: ["display_truck"],
        },
        {
            path       : "/camions",
            component  : React.lazy(() => import("app/main/components/inventory/truck/showTrucks/TruckList")),
            permissions: ["display_truck"],
        },

        //unit
        {
            path       : '/units',
            component  : React.lazy(() => import('app/main/components/inventory/unit/Units')),
            permissions: ['display_unit']
        },

        //warehouse
        {
            path       : "/entrepots/historique",
            component  : React.lazy(() => import("app/main/components/inventory/warehouse/warehouseHistoric/WarehouseHistoric")),
            permissions: ["display_warehouse"],
        },
        {
            path       : "/entrepots/emplacements",
            component  : React.lazy(() => import("app/main/components/inventory/warehouse/warehouseEmplacement/WarehouseEmplacements")),
            permissions: ["display_supply_emplacement"],
        },
        {
            path       : "/entrepots/nouveau-entrepot",
            component  : React.lazy(() => import("app/main/components/inventory/warehouse/createWarehouse/CreateWarehouse")),
            permissions: ["manage_warehouse"],
        },
        {
            path       : "/entrepots/equipements",
            component  : React.lazy(() => import("app/main/components/inventory/warehouse/warehouseInventory/WarehouseInventoryStock")),
            permissions: ["display_supply_emplacement"],
        },
        {
            path       : "/entrepots/materiaux",
            component  : React.lazy(() => import("app/main/components/inventory/warehouse/warehouseInventory/WarehouseInventorySupplyBundle")),
            permissions: ["display_supply_emplacement"],
        },
        {
            path       : "/entrepots/inventaires",
            component  : React.lazy(() => import("app/main/components/inventory/warehouse/warehouseInventory/WarehouseInventory")),
            permissions: ["display_supply_emplacement"],
        },
        {
            path       : "/entrepots/modifier/:id",
            component  : React.lazy(() => import("app/main/components/inventory/warehouse/updateWarehouse/UpdateWarehouse")),
            permissions: ["manage_warehouse"],
        },
        {
            path       : "/entrepots/:id",
            component  : React.lazy(() => import("app/main/components/inventory/warehouse/showWarehouse/WarehousePage")),
            permissions: ["display_warehouse"],
        },
        {
            path       : "/entrepots",
            component  : React.lazy(() => import("app/main/components/inventory/warehouse/showWarehouses/WarehouseList")),
            permissions: ["display_warehouse"],
        },

        //expedibox
        {
            path       : "/expedibox/:id",
            component  : React.lazy(() => import("app/main/components/inventory/expedibox/showLocker/Locker")),
            permissions: ["display_warehouse"],
        },
        {
            path       : "/expedibox",
            component  : React.lazy(() => import("app/main/components/inventory/expedibox/showLockers/Lockers")),
            permissions: ["display_warehouse"],
        },

        //requested supplies
        {
            path       : "/requete-client",
            component  : React.lazy(() => import("app/main/components/inventory/requestedSupplies/RequestedSuppliesIndex/RequestedSuppliesIndex")),
            permissions: ["manage_requested_supplies"],
        },
    ],
};