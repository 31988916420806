export default getNavigationConfig;

function getNavigationConfig(currentPanel) {
    let permissions = localStorage.getItem("userInfo") ? JSON.parse(localStorage.getItem("userInfo")).permissions : [];
    return filterRoutes(navigationRoutes, currentPanel, permissions);
}

function filterRoutes(routes, currentPanel, permissions) {
    let filteredRoute = [];
    routes.forEach(function (route) {
        if (route.type === "collapse" && route.panels.includes(currentPanel)) {
            const collapse = JSON.parse(JSON.stringify(route));
            collapse.children = filterRoutes(route.children, currentPanel, permissions);
            if (collapse.children.length !== 0) {
                filteredRoute.push(collapse);
            }
        }
        if (route.type === "item" && route.panels.includes(currentPanel) &&
            route.permissions.every((requiredPermission) => {
                return permissions.find(function (permission) {
                    return permission.code === requiredPermission;
                });
            })) {

            filteredRoute.push(route);
        }
    });
    return filteredRoute;
}

const navigationRoutes = [
    //Admin Panel
    {
        "id"         : "tableau-de-bord",
        "title"      : "Tableau de bord",
        "type"       : "item",
        "icon"       : "faClipboardList",
        "url"        : "/tableau-de-bord",
        "panels"     : ["adminPanel"],
        "permissions": ["warehouse_panel", "administrator_panel", "truck_panel"],
    },

    {
        "id"         : "customer-component",
        "title"      : "Clients",
        "type"       : "item",
        "icon"       : "faUsers",
        "url"        : "/clients",
        "panels"     : ["adminPanel"],
        "permissions": ["display_atombo2_customer"],
    },

    {
        "id"      : "inventory",
        "title"   : "Inventaire",
        "type"    : "collapse",
        "icon"    : "faForklift",
        "panels"  : ["adminPanel"],
        "children": [
            {
                "id"         : "materiaux",
                "title"      : "Matériaux",
                "type"       : "item",
                "icon"       : "faBoxesAlt",
                "url"        : "/materiaux",
                "panels"     : ["adminPanel"],
                "permissions": ["display_supply"],
            },
            {
                "id"         : "entrepot",
                "title"      : "Entrepôts",
                "type"       : "item",
                "icon"       : "faWarehouseAlt",
                "url"        : "/entrepots",
                "panels"     : ["adminPanel"],
                "permissions": ["display_warehouse"],
            },
            {
                "id"         : "trucks",
                "title"      : "Camions",
                "type"       : "item",
                "icon"       : "faTruck",
                "url"        : "/camions",
                "panels"     : ["adminPanel"],
                "permissions": ["display_truck"],
            },
            {
                "id"         : "delivery",
                "title"      : "Livraisons",
                "type"       : "item",
                "icon"       : "faTruckLoading",
                "url"        : "/livraisons",
                "panels"     : ["adminPanel"],
                "permissions": ["display_delivery"],
            },
            {
                "id"         : "equipement",
                "title"      : "Équipements",
                "type"       : "item",
                "icon"       : "faBarcodeAlt",
                "url"        : "/equipements",
                "panels"     : ["adminPanel"],
                "permissions": ["display_stored_supply"],
            },
            {
                "id"         : "Expedibox",
                "title"      : "Expedibox",
                "type"       : "item",
                "icon"       : "faHandHoldingBox",
                "url"        : "/expedibox",
                "panels"     : ["adminPanel"],
                "permissions": ["display_expedibox_locker"],
            },
            {
                "id"         : "requested_supplies",
                "title"      : "Requête clients",
                "type"       : "item",
                "icon"       : "faHandshakeSimple",
                "url"        : "/requete-client",
                "panels"     : ["adminPanel"],
                "permissions": ["manage_requested_supplies"],
            },
            {
                "id"      : "toolsAdmin",
                "title"   : "Outils",
                "type"    : "collapse",
                "icon"    : "faTools",
                "panels"  : ["adminPanel"],
                "children": [
                    {
                        "id"         : "setTopBoxesOnBenchAdmin",
                        "title"      : "Décodeurs",
                        "type"       : "item",
                        "icon"       : "faCarBattery",
                        "url"        : "/outils/decodeurs",
                        "panels"     : ["adminPanel"],
                        "permissions": ["manage_stored_supply"],
                    },
                    {
                        "id"         : "routersAdmin",
                        "title"      : "Routeurs",
                        "type"       : "item",
                        "icon"       : "faRouter",
                        "url"        : "/outils/routeurs",
                        "panels"     : ["adminPanel"],
                        "permissions": ["manage_stored_supply"],
                    },
                    {
                        "id"         : "scanAdmin",
                        "title"      : "Scanner",
                        "type"       : "item",
                        "icon"       : "faScanner",
                        "url"        : "/equipements/scan",
                        "panels"     : ["adminPanel"],
                        "permissions": ["manage_stored_supply"],
                    },
                ],
            },
        ],
    },

    {
        "id"      : "network",
        "title"   : "Réseau",
        "type"    : "collapse",
        "icon"    : "faNetworkWired",
        "panels"  : ["adminPanel"],
        "children": [
            {
                "id"         : "cells",
                "title"      : "Cellules",
                "type"       : "item",
                "icon"       : "faBullseye",
                "url"        : "/cellules",
                "panels"     : ["adminPanel"],
                "permissions": ["display_cell"],
            },
            {
                "id"         : "Nodes",
                "title"      : "Nodes",
                "type"       : "item",
                "icon"       : "faDotCircle",
                "url"        : "/nodes",
                "panels"     : ["adminPanel"],
                "permissions": ["display_node"],
            },
            {
                "id"         : "olts",
                "title"      : "Têtes de lignes",
                "type"       : "item",
                "icon"       : "faDotCircle",
                "url"        : "/opticalLineTerminations",
                "panels"     : ["adminPanel"],
                "permissions": ["display_internet_services"],
            },
            {
                "id"         : "networkRings",
                "title"      : "Anneaux réseau",
                "type"       : "item",
                "icon"       : "faRing",
                "url"        : "/networkRings",
                "panels"     : ["adminPanel"],
                "permissions": ["display_internet_services"],
            },
            {
                "id"         : "gateway",
                "title"      : "Gateway",
                "type"       : "item",
                "icon"       : "faExchangeAlt",
                "url"        : "/gateways",
                "panels"     : ["adminPanel"],
                "permissions": ["display_internet_services"],
            },
            {
                "id"         : "Nom alarms SNMP",
                "title"      : "Alarms SNMP",
                "type"       : "item",
                "icon"       : "faExchangeAlt",
                "url"        : "/SNMPAlarmsName",
                "panels"     : ["adminPanel"],
                "permissions": ["manage_snmp_alarms_name"],
            },
        ],
    },

    {
        "id"      : "tvService",
        "title"   : "Télévision",
        "type"    : "collapse",
        "icon"    : "faTv",
        "panels"  : ["adminPanel"],
        "children": [
            {
                "id"         : "channels",
                "title"      : "Chaînes",
                "type"       : "item",
                "icon"       : "faAddressCard",
                "url"        : "/chaines",
                "panels"     : ["adminPanel"],
                "permissions": ["display_atombo2_customer"],
            },
            {
                "id"         : "bases",
                "title"      : "Bases",
                "type"       : "item",
                "icon"       : "faClone",
                "url"        : "/bases",
                "panels"     : ["adminPanel"],
                "permissions": ["display_television_service"],
            },
            {
                "id"         : "Groupes",
                "title"      : "Groupes",
                "type"       : "item",
                "icon"       : "faCubes",
                "url"        : "/groupe-chaines",
                "panels"     : ["adminPanel"],
                "permissions": ["display_television_service"],
            },
            {
                "id"         : "commercialGroupes",
                "title"      : "Groupes commerciaux",
                "type"       : "item",
                "icon"       : "faStoreAlt",
                "url"        : "/groupe-commercials",
                "panels"     : ["adminPanel"],
                "permissions": ["manage_television_service"],
            },
            {
                "id"         : "megaGroupes",
                "title"      : "Méga groupes",
                "type"       : "item",
                "icon"       : "faBetamax",
                "url"        : "/mega-groupe-chaines",
                "panels"     : ["adminPanel"],
                "permissions": ["display_television_service"],
            },
            {
                "id"         : "pickAndPackOption",
                "title"      : "Options Sur Mesure",
                "type"       : "item",
                "icon"       : "faLayerPlus",
                "url"        : "/option-sur-mesures",
                "panels"     : ["adminPanel"],
                "permissions": ["display_television_service"],
            },
            {
                "id"         : "pickAndPack",
                "title"      : "Sur Mesure",
                "type"       : "item",
                "icon"       : "faBraille",
                "url"        : "/sur-mesures",
                "panels"     : ["adminPanel"],
                "permissions": ["display_television_service"],
            },
            {
                "id"         : "brokenChannelLock",
                "title"      : "Barrures brisées",
                "type"       : "item",
                "icon"       : "faLockOpen",
                "url"        : "/barrure-brise",
                "panels"     : ["adminPanel"],
                "permissions": ["manage_broken_channel_lock"],
            },
            {
                "id"         : "apmaxDifference",
                "title"      : "Gestion ap-max",
                "type"       : "item",
                "icon"       : "faLaptopCode",
                "url"        : "/apmax",
                "panels"     : ["adminPanel"],
                "permissions": ["manage_apmax"],
            },
            {
                "id"         : "televisionStats",
                "title"      : "Statistique télévision",
                "type"       : "item",
                "icon"       : "faAddressCard",
                "url"        : "/television/stats",
                "panels"     : ["adminPanel"],
                "permissions": ["manage_television_service"],
            },
        ],
    },

    {
        "id"      : "internetService",
        "title"   : "Internet",
        "type"    : "collapse",
        "icon"    : "faEthernet",
        "panels"  : ["adminPanel"],
        "children": [
            {
                "id"         : "internetPlanCategory",
                "title"      : "Catégories forfaits",
                "type"       : "item",
                "icon"       : "faCreditCardBlank",
                "url"        : "/categories-forfait-internet",
                "panels"     : ["adminPanel"],
                "permissions": ["manage_internet_services"],
            },
            {
                "id"         : "internetPlan",
                "title"      : "Forfaits Internet",
                "type"       : "item",
                "icon"       : "faCreditCardFront",
                "url"        : "/forfaits-internet",
                "panels"     : ["adminPanel"],
                "permissions": ["manage_internet_services"],
            },
            {
                "id"         : "internetPlanPackage",
                "title"      : "Groupes Internet",
                "type"       : "item",
                "icon"       : "faObjectGroup",
                "url"        : "/groupes-forfait-internet",
                "panels"     : ["adminPanel"],
                "permissions": ["manage_internet_services"],
            },
            {
                "id"         : "internetSessions",
                "title"      : "Sessions Internet",
                "type"       : "item",
                "icon"       : "faUserClock",
                "url"        : "/sessions",
                "panels"     : ["adminPanel"],
                "permissions": ["manage_internet_services"],
            },
            {
                "id"         : "staticIp",
                "title"      : "IP statiques",
                "type"       : "item",
                "icon"       : "faBlockBrick",
                "url"        : "/ip-statiques",
                "panels"     : ["adminPanel"],
                "permissions": ["manage_internet_services"],
            },
            {
                "id"      : "InternetTools",
                "title"   : "Configuration",
                "type"    : "collapse",
                "icon"    : "faTools",
                "panels"  : ["adminPanel"],
                "children": [
                    {
                        "id"         : "SellableInternetSupply",
                        "title"      : "Matériaux Internet",
                        "type"       : "item",
                        "icon"       : "faBoxCheck",
                        "url"        : "/internets/equipements",
                        "panels"     : ["adminPanel"],
                        "permissions": ["manage_internet_services"],
                    },
                ],
            }
        ],
    },

    {
        "id"      : "phoneService",
        "title"   : "Téléphonie",
        "type"    : "collapse",
        "icon"    : "faPhone",
        "panels"  : ["adminPanel"],
        "children": [
            {
                "id"         : "phonePlan",
                "title"      : "Forfaits téléphone",
                "type"       : "item",
                "icon"       : "faCreditCardBlank",
                "url"        : "/forfaits-telephone",
                "panels"     : ["adminPanel"],
                "permissions": ["display_telephone_service"],
            },
            {
                "id"         : "rateCenter",
                "title"      : "Villes configurées",
                "type"       : "item",
                "icon"       : "faBroadcastTower",
                "url"        : "/rate-centers",
                "panels"     : ["adminPanel"],
                "permissions": ["manage_telephone_service"],
            },
            {
                "id"         : "callRates",
                "title"      : "Prix longues distances",
                "type"       : "item",
                "icon"       : "faSignal",
                "url"        : "/longue-distance",
                "panels"     : ["adminPanel"],
                "permissions": ["manage_telephone_service"],
            },
            {
                "id"         : "pbx",
                "title"      : "PBX",
                "type"       : "item",
                "icon"       : "faHdd",
                "url"        : "/pbxs",
                "panels"     : ["adminPanel"],
                "permissions": ["display_pbx"],
            },
            {
                "id"         : "phoneNumbeForTest",
                "title"      : "Numéros de test",
                "type"       : "item",
                "icon"       : "faPhonePlus",
                "url"        : "/numero-telephone-tests",
                "panels"     : ["adminPanel"],
                "permissions": ["manage_telephone_services"],
            },
            {
                "id"         : "phoneLineDeleted",
                "title"      : "Numéros supprimés",
                "type"       : "item",
                "icon"       : "faPhoneSlash",
                "url"        : "/numero-telephone/supprimer",
                "panels"     : ["adminPanel"],
                "permissions": ["manage_telephone_services"],
            },
            {
                "id"         : "phoneLinePortOutDeleted",
                "title"      : "Numéros en portage",
                "type"       : "item",
                "icon"       : "faPhoneArrowUpRight",
                "url"        : "/numero-telephone/supprimer-portage",
                "panels"     : ["adminPanel"],
                "permissions": ["manage_telephone_services"],
            },
            {
                "id"         : "911",
                "title"      : "Documents E911",
                "type"       : "item",
                "icon"       : "faUserPolice",
                "url"        : "/Documents-E911",
                "panels"     : ["adminPanel"],
                "permissions": ["manage_e911Documents"],
            },
        ],
    },

    //Business
    {
        "id"      : "businessService",
        "title"   : "Affaires",
        "type"    : "collapse",
        "icon"    : "faBuilding",
        "panels"  : ["adminPanel"],
        "children": [
            {
                "id"         : "businessPlans",
                "title"      : "Plans",
                "type"       : "item",
                "icon"       : "faTag",
                "url"        : "/services-affaires/plans",
                "panels"     : ["adminPanel"],
                "permissions": ["display_business_services"],
            },
        ]
    },

    {
        "id"      : "system",
        "title"   : "Système",
        "type"    : "collapse",
        "icon"    : "faCogs",
        "panels"  : ["adminPanel"],
        "children": [
            {
                "id"         : "unit",
                "title"      : "Unités",
                "type"       : "item",
                "icon"       : "faRuler",
                "url"        : "/units",
                "panels"     : ["adminPanel"],
                "permissions": ["display_unit"],
            },
            {
                "id"         : "suggestion",
                "title"      : "Suggestions",
                "type"       : "item",
                "icon"       : "faCommentsAlt",
                "url"        : "/suggestions",
                "panels"     : ["adminPanel"],
                "permissions": ["display_feedback"],
            },
            {
                "id"         : "lead_sources",
                "title"      : "Sources Marketing",
                "type"       : "item",
                "icon"       : "faCommentsAlt",
                "url"        : "/source-marketing",
                "panels"     : ["adminPanel"],
                "permissions": ["manage_lead_sources"],
            },
            {
                "id"         : "pbxqueues",
                "title"      : "Pbx Queues",
                "type"       : "item",
                "icon"       : "faPeopleLine",
                "url"        : "/pbxqueues",
                "panels"     : ["adminPanel"],
                "permissions": ["display_pbx_queues"],
            }
        ],
    },

    {
        "id"      : "finances",
        "title"   : "Finances",
        "type"    : "collapse",
        "icon"    : "faDollarSign",
        "panels"  : ["adminPanel"],
        "children": [
            {
                "id"         : "fee",
                "title"      : "Frais",
                "type"       : "item",
                "icon"       : "faTag",
                "url"        : "/frais",
                "panels"     : ["adminPanel"],
                "permissions": ["display_fee"],
            },
            {
                "id"         : "report",
                "title"      : "Rapports",
                "type"       : "item",
                "icon"       : "faChartWaterfall",
                "url"        : "/rapports",
                "panels"     : ["adminPanel"],
                "permissions": ["manage_invoice"],
            },
        ],
    },

    {
        "id"      : "demand",
        "title"   : "Tâches sur demande",
        "type"    : "collapse",
        "icon"    : "faPeopleGroup",
        "panels"  : ["adminPanel"],
        "children": [
            {
                "id"         : "contractors",
                "title"      : "Sous-traitants",
                "type"       : "item",
                "icon"       : "faPeopleGroup",
                "url"        : "/sous-traitants",
                "panels"     : ["adminPanel"],
                "permissions": ["display_contractors"],
            },
            {
                "id"         : "sdWorkOrders",
                "title"      : "Drops sur demande",
                "type"       : "item",
                "icon"       : "faClipboardListCheck",
                "url"        : "/drops-sur-demande",
                "panels"     : ["adminPanel"],
                "permissions": ["display_SD_drops"],
            },
            {
                "id"         : "waitingSdWorkOrders",
                "title"      : "Drops à valider",
                "type"       : "item",
                "icon"       : "faClipboardList",
                "url"        : "/drops-sur-demande-a-valider",
                "panels"     : ["adminPanel"],
                "permissions": ["display_SD_drops"],
            },
            {
                "id"         : "prepareSuppliesSdWorkOrders",
                "title"      : "Drops en préparation",
                "type"       : "item",
                "icon"       : "faClipboardList",
                "url"        : "/drops-sur-demande-en-preparation",
                "panels"     : ["adminPanel"],
                "permissions": ["display_SD_drops"],
            },
            {
                "id"         : "toAttributeTaskSdWorkOrders",
                "title"      : "Drops à attribuer",
                "type"       : "item",
                "icon"       : "faClipboardList",
                "url"        : "/drops-sur-demande-a-attribuer",
                "panels"     : ["adminPanel"],
                "permissions": ["display_SD_drops"],
            },
            {
                "id"         : "repairs",
                "title"      : "Réparations",
                "type"       : "item",
                "icon"       : "faScrewdriverWrench",
                "url"        : "/reparations",
                "panels"     : ["adminPanel"],
                "permissions": ["display_repairs"],
            },
            {
                "id"         : "repairs-waiting-completion",
                "title"      : "Rép. à compléter",
                "type"       : "item",
                "icon"       : "faScrewdriver",
                "url"        : "/reparations-attente-completion",
                "panels"     : ["adminPanel"],
                "permissions": ["display_repairs"],
            },
            {
                "id"         : "repairs-waiting",
                "title"      : "Rép. à valider",
                "type"       : "item",
                "icon"       : "faScrewdriver",
                "url"        : "/reparations-attente-validation",
                "panels"     : ["adminPanel"],
                "permissions": ["display_repairs"],
            },
            {
                "id"         : "contractorDemand",
                "title"      : "Tâches externes",
                "type"       : "item",
                "icon"       : "faShovel",
                "url"        : "/tache-externes",
                "panels"     : ["adminPanel"],
                "permissions": ['display_repairs', 'display_SD_drops'],
            },
        ],
    },

    {
        "id"      : "Administrateurs",
        "title"   : "Administrateurs",
        "type"    : "collapse",
        "icon"    : "faUsersCog",
        "panels"  : ["adminPanel"],
        "children": [
            {
                "id"         : "administrateurs-component",
                "title"      : "Administrateurs",
                "type"       : "item",
                "icon"       : "faUsers",
                "url"        : "/administrateurs",
                "panels"     : ["adminPanel"],
                "permissions": ["super_admin"],
            },
            {
                "id"         : "responsable_drop",
                "title"      : "Responsable de drop",
                "type"       : "item",
                "icon"       : "faUserTag",
                "url"        : "/responsable-drop",
                "panels"     : ["adminPanel"],
                "permissions": ["manage_SD_drops"],
            },
            {
                "id"         : "responsable_repair",
                "title"      : "Responsable réparation",
                "type"       : "item",
                "icon"       : "faUserPen",
                "url"        : "/responsable-reparation",
                "panels"     : ["adminPanel"],
                "permissions": ["manage_repairs"],
            },
        ],
    },

    //Warehouse Panel
    {
        "id"         : "warehouse-tableau-de-bord",
        "title"      : "Tableau de bord",
        "type"       : "item",
        "icon"       : "faClipboardList",
        "url"        : "/tableau-de-bord",
        "panels"     : ["warehousePanel"],
        "permissions": ["display_warehouse"],
    },
    {
        "id"      : "local_inventory",
        "title"   : "Inventaire local",
        "type"    : "collapse",
        "icon"    : "faLocationDot",
        "panels"  : ["warehousePanel"],
        "children": [
            {
                "id"         : "inventory",
                "title"      : "Tout",
                "type"       : "item",
                "icon"       : "faForklift",
                "url"        : "/entrepots/inventaires",
                "panels"     : ["warehousePanel"],
                "permissions": ["display_stored_supply"],
            },
            {
                "id"         : "local_stock",
                "title"      : "Équipements",
                "type"       : "item",
                "icon"       : "faBarcodeAlt",
                "url"        : "/entrepots/equipements",
                "panels"     : ["warehousePanel"],
                "permissions": ["display_stored_supply"],
            },
            {
                "id"         : "local_supply_bundle",
                "title"      : "Matériaux",
                "type"       : "item",
                "icon"       : "faBoxesAlt",
                "url"        : "/entrepots/materiaux",
                "panels"     : ["warehousePanel"],
                "permissions": ["display_stored_supply"],
            },
        ],
    },
    {
        "id"      : "global_inventory",
        "title"   : "Inventaire global",
        "type"    : "collapse",
        "icon"    : "faEarthAmericas",
        "panels"  : ["warehousePanel"],
        "children": [
            {
                "id"         : "materiaux",
                "title"      : "Modèles",
                "type"       : "item",
                "icon"       : "faBoxTaped",
                "url"        : "/materiaux",
                "panels"     : ["warehousePanel"],
                "permissions": ["display_supply_minimum"],
            },
            {
                "id"         : "equipements",
                "title"      : "Équipements",
                "type"       : "item",
                "icon"       : "faBarcodeAlt",
                "url"        : "/equipements",
                "panels"     : ["warehousePanel"],
                "permissions": ["display_stored_supply"],
            },
        ],
    },

    {
        "id"         : "delivery",
        "title"      : "Livraisons",
        "type"       : "item",
        "icon"       : "faTruckLoading",
        "url"        : "/livraisons",
        "panels"     : ["warehousePanel"],
        "permissions": ["display_delivery"],
    },
    {
        "id"         : "commande_reception",
        "title"      : "Réception de commande",
        "type"       : "item",
        "icon"       : "faHandHoldingBox",
        "url"        : "/reception",
        "panels"     : ["warehousePanel"],
        "permissions": ["manage_stored_supply"],
    },
    {
        "id"         : "requested_supply",
        "title"      : "Requêtes Client",
        "type"       : "item",
        "icon"       : "faHandshakeSimple",
        "url"        : "/requete-client",
        "panels"     : ["warehousePanel"],
        "permissions": ["manage_requested_supplies"],
    },
    {
        "id"      : "toolsWarehouse",
        "title"   : "Outils",
        "type"    : "collapse",
        "icon"    : "faTools",
        "panels"  : ["warehousePanel"],
        "children": [
            {
                "id"         : "setTopBoxesOnBenchWarehouse",
                "title"      : "Décodeurs",
                "type"       : "item",
                "icon"       : "faCarBattery",
                "url"        : "/outils/decodeurs",
                "panels"     : ["warehousePanel"],
                "permissions": ["manage_stored_supply"],
            },
            {
                "id"         : "scanWarehouse",
                "title"      : "Scanner",
                "type"       : "item",
                "icon"       : "faScanner",
                "url"        : "/equipements/scan",
                "panels"     : ["warehousePanel"],
                "permissions": ["manage_stored_supply"],
            },
        ],
    },

    {
        "id"         : "minimum",
        "title"      : "Minimums",
        "type"       : "item",
        "icon"       : "faAbacus",
        "url"        : "/minimums",
        "panels"     : ["warehousePanel"],
        "permissions": ["display_supply_minimum"],
    },

    {
        "id"         : "emplacement",
        "title"      : "Emplacements",
        "type"       : "item",
        "icon"       : "faInventory",
        "url"        : "/entrepots/emplacements",
        "panels"     : ["warehousePanel"],
        "permissions": ["display_supply_emplacement"],
    },

    {
        "id"         : "history",
        "title"      : "Historique",
        "type"       : "item",
        "icon"       : "faCalendarAlt",
        "url"        : "/entrepots/historique",
        "panels"     : ["warehousePanel"],
        "permissions": ["display_warehouse"],
    },

    //TruckPanel
    {
        "id"         : "truck-tableau-de-bord",
        "title"      : "Tableau de bord",
        "type"       : "item",
        "icon"       : "faClipboardList",
        "url"        : "/tableau-de-bord",
        "panels"     : ["truckPanel"],
        "permissions": ["display_truck"],
    },

    {
        "id"         : "inventory",
        "title"      : "Inventaire",
        "type"       : "item",
        "icon"       : "faForklift",
        "url"        : "/camions/inventaires",
        "panels"     : ["truckPanel"],
        "permissions": ["display_stored_supply"],
    },

    {
        "id"         : "repairs",
        "title"      : "Réparations",
        "type"       : "item",
        "icon"       : "faScrewdriver",
        "url"        : "/reparations",
        "panels"     : ["truckPanel"],
        "permissions": ["display_repairs"],
    },

    {
        "id"         : "quincaillerie",
        "title"      : "Quincaillerie",
        "type"       : "item",
        "icon"       : "faToolbox",
        "url"        : "/quincailleries",
        "panels"     : ["truckPanel"],
        "permissions": ["display_supply"],
    },

    {
        "id"         : "equipements",
        "title"      : "Équipements",
        "type"       : "item",
        "icon"       : "faBarcodeAlt",
        "url"        : "/equipements",
        "panels"     : ["truckPanel"],
        "permissions": ["display_stored_supply"],
    },

    {
        "id"         : "delivery",
        "title"      : "Livraisons",
        "type"       : "item",
        "icon"       : "faTruckLoading",
        "url"        : "/livraisons",
        "panels"     : ["truckPanel"],
        "permissions": ["display_delivery"],
    },

    {
        "id"         : "retour",
        "title"      : "Livraison de retour",
        "type"       : "item",
        "icon"       : "faHandHoldingBox",
        "url"        : "/retour",
        "panels"     : ["truckPanel"],
        "permissions": ["display_delivery"],
    },

    {
        "id"         : "minimum",
        "title"      : "Minimums",
        "type"       : "item",
        "icon"       : "faAbacus",
        "url"        : "/minimums",
        "panels"     : ["truckPanel"],
        "permissions": ["display_supply_minimum"],
    },

    {
        "id"         : "materiaux",
        "title"      : "Matériaux",
        "type"       : "item",
        "icon"       : "faBoxesAlt",
        "url"        : "/materiaux",
        "panels"     : ["truckPanel"],
        "permissions": ["display_supply_minimum"],
    },

    {
        "id"         : "customer-component",
        "title"      : "Clients",
        "type"       : "item",
        "icon"       : "faUsers",
        "url"        : "/clients",
        "panels"     : ["truckPanel"],
        "permissions": ["display_atombo2_customer"],
    },

    {
        "id"         : "historic",
        "title"      : "Historique",
        "type"       : "item",
        "icon"       : "faCalendarAlt",
        "url"        : "/camions/historique",
        "panels"     : ["truckPanel"],
        "permissions": ["display_truck"],
    },

    //ContractorPanel
    {
        "id"         : "contractorDemands",
        "title"      : "Services sur demande",
        "type"       : "item",
        "icon"       : "faWrench",
        "url"        : "/services-sur-demande",
        "panels"     : ["contractorPanel"],
        "permissions": ["contractor_panel"],
    },
];